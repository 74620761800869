@import 'color/colors';

.tasksSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
  width: 100%;
  max-width: 1080px;
  padding: 0;
  color: $dark-50;
  margin: 20px 0;

  &__header {
    @include text--h1;
    padding: 12px 0;
    svg {
      font-size: 1.25em;
      margin-top: 4px;
      margin-right: 5px;
      color: inherit;
    }
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  /** *** LIST START *** **/
  &__list {
    display: grid;
    grid-template-columns: minmax(240px, 3.5fr) minmax(120px, 2fr) minmax(112px, 140px);
    grid-column-gap: 0;
    background-color: $white;
    width: 100%;
    min-width: 800px;
    overflow-y: auto;
    max-height: 325px;
    justify-content: stretch;
    // border: thin solid $border;
    border-radius: 16px;
    box-shadow: $shadow__table;

    @media (max-width: 876px) {
      min-width: 680px;
    }

    &.isMyDashboard {
      grid-template-columns:
        minmax(240px, 3.5fr) minmax(120px, 1.5fr) minmax(120px, 1.5fr)
        minmax(112px, 140px);
      min-width: 800px;
    }

    &.unsetHeight {
      max-height: unset;
      overflow-y: hidden;
    }

    &Header {
      display: flex;
      align-items: center;
      background-color: $white;
      height: 40px;
      &Label {
        background-color: $white;
        height: 40px;
        cursor: pointer;
        margin: 0;
        padding-right: 8px;
        display: flex;
        align-items: center;
        // border-bottom: thin solid $light-95;
        top: 0;
        position: sticky;
        font-size: 14px;
        font-weight: 400;
        color: #afbdc5;

        &.first {
          padding-left: 40px;
        }
        svg {
          color: $text--dark-1;
          margin-left: 2px;
        }
        &:hover {
          color: $dark-75;
          text-decoration: underline;
          svg {
            color: $link--light;
          }
        }
      }
    }
    &Row {
      display: contents;
      cursor: pointer;
      background-color: inherit;
      &:hover {
        background-color: $hover;
        .tasksSection__listActions {
          display: flex;
        }
      }
      &.isAllTasks {
        .tasksSection__listItem {
          height: 80px;
          h4 {
            height: 80px;
          }
        }
      }
    }
    &Item {
      background-color: inherit;
      border-top: thin solid $light-95;
      color: #2d363c;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: normal;
      height: 60px;
      justify-content: center;
      margin: 0;
      padding: 0 8px 0 0;
      min-height: 80px;
      &.first {
        color: #0d2238;
        font-size: 16px;
        font-weight: bold;
        grid-column: 1;
        padding-left: 40px;
        span.isMint {
          color: $mint;
        }
      }
      &.last {
        align-items: flex-end;
      }
      span {
        font-size: 12px;
        color: #2d363c;
        font-weight: 500;
        margin-top: 8px;
      }
      h4 {
        align-self: flex-start;
        background-color: inherit;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: normal;
        height: 60px;
        justify-content: center;
        margin: 0;
        width: 100%;
      }
      &--link {
        text-decoration: underline;
        &:hover {
          color: $link;
        }
      }
    }
    &Actions {
      align-items: center;
      backdrop-filter: blur(1px);
      background-color: $transparentHover;
      display: none;
      height: 58px;
      justify-content: flex-end;
      margin-right: 20px;
      position: absolute;
      width: fit-content;
      .myBtn {
        margin: 0 8px;
        background-color: $white;
        opacity: 1;
      }
    }
    &Toggle {
      grid-column-start: 1;
      grid-column-end: 6;
      margin: 4px 0;
      color: $text--dark-1;
      svg {
        margin-left: 4px;
      }
      &:hover {
        color: $link--light;
      }
    }
  }
  /** *** LIST END *** **/
}
