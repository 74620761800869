* {
  box-sizing: border-box;
}

html {
  @include font--primary-regular;
  // @include size--base-1;
  color: $text--dark;
  height: 100%;
  min-height: 100%;
}

body {
  background: $bg--page;
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  #hubspot-messages-iframe-container {
    bottom: 16px !important; // Used to override hubspot widget's use of !important
  }
  // @media (min-width: 2300px) {
  //   overflow-y: auto;
  // }
}

/**
 * until https://github.com/facebook/create-react-app/issues/11773
 * gets fixed, let's hide the problem and not address it
 */
body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  box-shadow: inset 0 0 6px #a4a4a4;
  opacity: 0.3;
  transition: 0.05s all ease;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px #a4a4a4;
  opacity: 0.4;
  transition: 0.05s all ease;
  &:window-inactive {
    opacity: 0.1;
  }
}

button {
  @include font--primary-regular;
}

a {
  @include font--primary-regular;
  color: $blue;
  text-decoration: none;
}

input {
  &::-webkit-input-placeholder {
    color: $text--placeholder;
    font-style: italic;
  }
  &:-moz-placeholder {
    color: $text--placeholder;
    font-style: italic;
  }
  &::-moz-placeholder {
    color: $text--placeholder;
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: $text--placeholder;
    font-style: italic;
  }
}

a:hover {
  cursor: pointer;
  color: $blue--light;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $bg--page;
}

.app-verify {
  color: $white;
  margin-right: 8px;
  position: fixed;
  text-align: center;
  background-color: $low;
  z-index: 9;
  display: flex;
  padding: 0 8px 0 12px;
  border-radius: 20px;
  align-items: center;
  top: $topbar-height + 12px;
  box-shadow: $shadow;
  height: 30px;
  right: 332px;
  &__icon {
    color: $low--dark;
    margin-right: 4px;
  }
  &__hide {
    padding: 0 4px;
    margin: 0;
    margin-left: 8px;
    svg {
      color: $white;
    }
    &:hover {
      svg {
        color: $link--light;
      }
    }
  }
  h4 {
    @include text--h4;
    color: $white;
  }
  &.login {
    top: 0;
  }
  .myBtn--link {
    font-size: inherit;
  }
  @media (max-width: 768px) {
    right: 100px;
  }
}
.app-redirect {
  position: fixed;
  padding: 0 8px 0 12px;
  border-radius: 20px;
  top: 14px;
  height: 30px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  background-color: $low;
  display: flex;
  align-items: center;
  z-index: 9;
  svg {
    color: $low--dark;
    margin-right: 4px;
  }
  h4 {
    @include text--h4;
    color: $white;
  }
  &.login {
    top: 8px;
  }
}

.inner,
.container {
  box-sizing: border-box;
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.page__heading {
  text-align: center;
  // @include size--base-4;
  font-weight: 400;
  color: #434c5f;
  margin-bottom: 50px;

  &.page__heading--large {
    // @include size--base-5;
    font-weight: 200;
  }
}

.grid--flex {
  display: flex;
}

.grid__column {
  margin-right: 16px;
}

.grid__column:last-child {
  margin-right: 0;
}

.loading {
  clear: both;
  color: $primary;
  font-size: 24px;
  margin: 1em;
  align-self: center;
  text-align: center;
  line-height: 2;
  text-shadow: 1px 1px 0 white;

  .loading__spinner {
    font-size: 1.5em;
    margin: 0.5em;
    color: $primary;
  }
}

.file-explorer__container {
  height: 640px;
  max-height: 100%;
  box-shadow: $shadow__table;
  border-radius: 4px;
}

.file-explorer__container .buik-logo-placeholder,
.file-explorer__container .be-logo {
  display: none !important;
}

.nav__dropdown .dd-menu-items ul {
  min-width: 225px;
  padding: 20px;
  font-size: 12px;
}

.nav__dropdown .dd-menu-items ul li > *:not(.dd-item-ignore),
.nav__dropdown .dd-menu-items ol li > *:not(.dd-item-ignore) {
  padding: 8px 0;
  text-transform: uppercase;
  color: $primary--dark;
}

.nav__dropdown .dd-menu-items ul li:hover:not([role='separator']):not(.separator),
.nav__dropdown .dd-menu-items ul li > *:focus,
.nav__dropdown .dd-menu-items ol li:hover:not([role='separator']):not(.separator),
.nav__dropdown .dd-menu-items ol li > *:focus {
  background: transparent;
  color: $primary--light;
}

.nav__dropdown .dd-menu-items .nav-dropdown__link:hover {
  color: $primary--light;
}

.nav__profile {
  padding-bottom: 16px;
}

.nav-dropdown__link {
  color: $primary;
  font-size: 12px;
}

.text--center {
  text-align: center;
}

.component__loader {
  align-self: center;
  text-align: center;
  margin: 1em;
  font-size: 42px;
  color: $primary;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.component__loading {
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
}

.component__loader--error {
  font-size: 21px;
  color: $dark-80;
  p {
    margin: 4px 0;
  }
}

.component__reload {
  margin: 4px 0;
  color: $link;
  &:hover {
    color: $link--light;
  }
}

.component__logout {
  padding: 8px 32px;
  margin: 12px;
  color: $white;
  background-color: $link;
  border: 3px solid $link;
  font-size: 15px;
  transition: all 0.15s;
  &:hover {
    background-color: $link--light;
    border: 3px solid $link--light;
    font-weight: bold;
  }
  &:active {
    background-color: $link;
    border: 3px solid $link;
    font-weight: bold;
  }
}

.card__footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 24px;
}

.loading-spinner {
  font-size: 36px;
  color: $primary;
  display: block;
  margin: 16px auto;
}

/** ReactModal styling **/

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  // transition: opacity 0.1s linear;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
