@import 'color/colors';

.workbench-actions {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: flex-end;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  min-width: 240px;
  @media (max-width: 1280px) {
    max-width: 600px;
  }
  @media (max-width: 1080px) {
    max-width: 520px;
  }
  @media (max-width: 568px) {
    max-width: 70%;
  }
  &--single {
    justify-content: center;
  }
}

.workbench-actions__btn {
  height: 48px;
  width: 250px;
  padding: 0;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -2px 4px 4px 0px #ccc;
  &:hover {
    box-shadow: -2px 4px 4px 0px #ccc;
  }
}
