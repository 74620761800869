@import 'color/colors';

.select-resource {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 600px;
  &__dropdown {
    position: absolute;
    right: -40px;
    top: 16px;
    .dd-menu-items {
      margin-left: 20px;
      margin-top: 0px;
      width: 125px;
      font-size: 14px;
      font-weight: 300;
      ul {
        color: $dark-50;
        border-radius: 2px;
        li {
          padding: 8px;
          cursor: pointer;
          transition: all 0.25s;
          &:hover {
            background-color: $light-90;
          }
          &:active {
            background-color: $light;
          }
        }
      }
    }
    @media (max-width: 876px) {
      right: 0;
    }
  }
  &__option {
    display: flex;
    justify-content: space-between;
    .myBtn {
      background-color: transparent;
      position: absolute;
      right: 0;
      &__tooltip {
        right: 0;
      }
      &:hover {
        background-color: $white;
      }
    }
    &-sublabel {
      @include text--h4;
      z-index: 1;
      backdrop-filter: blur(1px);
      background-color: white;
      opacity: 0.8;
      position: absolute;
      align-self: center;
      right: 32px;
      color: $low--dark;
    }
    &--focus {
      background-color: transparent;
    }
    &--delete {
      color: $orange;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      width: 140px;
      &:hover {
        color: $orange--dark;
      }
    }
  }
}
