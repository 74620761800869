@import 'color/colors';
@import 'navigation/navigation';

.basic-topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  box-shadow: 5px 0px 5px 0px $light;
  height: $topbar-height;
  display: flex;
  justify-content: space-between;
  z-index: $bars-z-index;
}

.basic-topbar--workbench {
  background-color: $white;
}

.basic-topbar__nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin: 5px 0;
  padding: 0 15px;
}

.basic-topbar__secondary-nav {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0;
  margin-right: 10px;
}

.basic-topbar__nav-list-item {
  color: $dark-60;
  list-style: none;
  flex: 1;
  span {
    vertical-align: middle;
    cursor: default;
  }
}

.basic-topbar__nav-list-item--secondary {
  list-style: none;
}

.basic-topbar__logo {
  margin-left: 40px;
  max-width: fit-content;
  img {
    cursor: pointer;
    width: 115px;
    filter: brightness(0);
  }
}

.basic-topbar__nav-link {
  color: $primary;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.85px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 6px 21px;
  display: inline-block;
  position: relative;

  &:hover {
    color: $primary--light;
  }

  &.active,
  &:active {
    color: $primary--dark;
  }
}
.basic-topbar__exit {
  color: $orange;
  &:hover {
    color: $orange--light;
  }
  &:active {
    color: $orange--dark;
  }
}
