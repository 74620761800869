@import 'color/colors';
@import 'navigation/navigation';

.project-header {
  position: relative;
  background-color: $bg--nav;
  border-left: 4px solid transparent;
  max-width: 99%;
  padding-left: 36px;
  cursor: default;
  display: flex;
  transition: all 0.2s ease-in-out;
  &:hover {
    .project-header__help a {
      color: $new-primary--light;
    }
  }
  &.highlight {
    border-left-color: $new-primary;
    cursor: pointer;
    .project-header__title {
      font-weight: 800;
      color: $new-primary;
    }
    &:hover {
      .project-header__help a {
        color: $new-primary--light;
      }
    }
  }
}

.is-cabinet {
  cursor: pointer;
}

.project-header__title {
  color: $text--light;
  font-size: 22px;
  font-weight: bold;
  margin: 4px 8px 8px 0;
  display: flex;
  align-items: center;
  position: relative;
  textarea {
    font-size: 1em;
    font-weight: 400;
    // color: $text--light;
    // background-color: $bg--nav;
    width: 100%;
    &:focus {
      border: thin solid $new-primary;
      outline: none;
    }
  }
  &.long {
    font-size: 1.2em;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  // sup {
  //   align-self: flex-start;
  //   margin-left: 2px;
  //   margin-right: 8px;
  //   margin-top: 2px;
  // }
  @media (max-width: 825px) {
    font-size: 1.5em;
    margin-bottom: 0;
    textarea {
      font-size: 0.9em;
    }
  }
}

.project-header__icon {
  color: $text--light;
  margin-right: 15px;
  &:hover {
    color: $new-primary;
  }
}

.project-header__help {
  border: none;
  background: transparent;
  margin: -2px 0 0 8px;
  cursor: help;
  position: absolute;
  top: -5px;
  right: 10px;
  a {
    color: $text--light;
    font-size: 14px;
    padding: 2px;
  }
  &:hover {
    a {
      color: $new-primary;
      font-weight: bold;
    }
  }
  &:focus {
    border: thin solid $new-primary;
    outline: none;
    a {
      outline: none;
    }
  }
  // @media (max-width: 825px) {
  //   font-size: 22px;
  // }
}

.project-header__edit-icon {
  color: $text--light;
  align-self: flex-start;
  position: absolute;
  border: none;
  background-color: transparent;
  left: -30px;
  top: -4px;

  &:hover {
    color: $primary--light;
    font-weight: bold;
    background-color: transparent;
  }
  &:focus {
    color: $primary--light;
    border: thin solid $primary--light;
    outline: none;
  }
}
