@import 'color/colors';

.pageLoader__wrapper {
  clear: both;
  color: $primary;
  margin: 1em;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  h2 {
    @include text--h2;
    color: $text--dark-1;
    svg {
      color: $warning;
      margin-right: 4px;
    }
  }
  img {
    width: 2em;
  }
}
