@import 'color/colors';
@import 'typography/type-variables';
@import 'mixins';
@import 'spacing/spacing';
@import 'navigation/navigation';

.workbench-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: $white;
  width: 98vw;
  max-width: 98vw;
  box-shadow: $shadow__table;
  border-radius: 4px;
  margin: 20px auto 84px;
  overflow-y: visible;
  overflow-x: visible;
  width: 100%;
  padding: 28px 90px 28px 40px;
  min-width: 680px;
  &--share {
    margin-bottom: 32px;
  }
  @media (max-width: 1440px) {
    padding-left: 60px;
  }
  @media (max-width: 1280px) {
    padding-left: 40px;
  }

  // @media (max-width: 1080px) {
  //   padding-left: 20px;
  // }
  @media (max-width: 876px) {
    // padding-left: 12px;
    min-width: unset;
    width: 100%;
    padding-right: 20px;
  }
  &--ios {
    margin-bottom: 415px;
  }
  &__fetching {
    display: flex;
    justify-content: center;
    color: $mint;
    font-size: 22px;
  }
}

.workbench-form__heading {
  @include text--h2;
  color: $text--dark-1;
  margin-top: 12px;
  max-width: 600px;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: thin solid $border;
  display: flex;
  flex-direction: column;
  small {
    @include text--body1;
    margin-top: 4px;
    svg {
      color: $low;
      margin-right: 4px;
    }
  }
}
.workbench-form__content {
  padding: 0 0 16px;
  flex: 1;
}

.workbench-form__reopen {
  border: none;
  outline: none;
  background: transparent;
  color: $link;
  font-size: 0.9em;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: $link--dark;
  }
}

.workbench-form__required {
  color: $red--light;
}

.workbench-form__title {
  text-align: center;
  margin: 0 auto;
  font-size: 22px;
  font-weight: bold;
  color: $text--dark;
  max-width: 525px;
}

.workbench-form__description {
  @include text--h4;
  font-size: 14px;
}

.workbench-form__actions {
  margin-top: -4px;
  .myBtn {
    margin: 4px 0;
    svg {
      margin-right: 4px;
    }
  }
}

.workbench-form__tracking {
  max-width: 525px;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  svg {
    color: $mint;
    margin-right: 4px;
  }
  @media (max-width: 825px) {
    margin-top: -15px;
    font-size: 0.9em;
  }
}

.workbench-form__group {
  flex: 1;
  position: relative;
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  .form__heading {
    font-size: 18px;
  }
}

.workbench-form__asterik {
  @include text--h4;
  max-width: 525px;
  margin: 4px 0;
  span {
    color: $red--light;
  }
}

.workbench-form__sub-heading {
  display: block;
  margin-top: 10px;
  color: $dark-75;
}

.workbench-form__group--is-hidden {
  position: absolute;
  height: 0;
  width: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.workbench-form__group--has-icon {
  position: relative;
}

.workbench-form__group--has-icon .form__control {
  padding-right: 42.5px;
}

.workbench-form__group--has-icon-left .form__control {
  padding-left: 52px;
  padding-right: 16px;
}

.workbench-form__group--has-icon-left .form-control__icon {
  right: auto;
  left: 1px;
  border-radius: 2px 0 0 2px;
}

.workbench-form__question-wrapper {
  display: contents;
  position: relative;
}

.workbench-form__input-group {
  position: relative;
}

.workbench-form__group--has-error,
.workbench-form__group--has-error .radio,
.workbench-form__group--has-error .checkbox,
.workbench-form__group--has-error .radio--inline,
.workbench-form__group--has-error .checkbox--inline {
  color: $red;
}

.workbench-form__group--has-error .input__group--addon {
  color: $red;
  background-color: #f2dede;
  border-color: $red;
}

.workbench-form__group--has-error .form-control__icon {
  color: $red;
}

.workbench-form__group--has-error > .form__control,
.workbench-form__group--has-error > .workbench-form__input-group {
  border-color: $red;
}

.workbench-form__group--has-error .form__control + .form-control__icon {
  background-color: rgba(136, 0, 51, 0.1);
  color: rgba(232, 53, 53, 0.4) !important;
}

.workbench-form__share-form-feature {
  position: relative;
  height: 0;
  left: -28px;
  top: 30px;
  margin: 0;
  &--no-padding {
    top: 18px;
  }
}

.workbench-form__errors {
  background-color: $red--light-3;
  color: $red--dark;
  padding: 5px 20px;
  max-width: 80%;
  margin: 15px auto;
  border-radius: 4px;
  max-width: 600px;
  p {
    margin: 5px auto;
  }
}

.workbench-form__loading {
  text-align: center;
}

.workbench-form__bottom {
  bottom: 28px;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
  &--modal {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    flex: 1;
    display: flex;
    .workbench-actions {
      justify-content: space-between;
    }
  }
}
