@import 'color/colors';

.formCustomDoc {
  &__header {
    color: $dark-75;
    margin: 0 0 8px;
    font-size: 1.1em;
    align-items: center;
    display: flex;
    position: relative;
    strong {
      color: $dark;
      margin-right: 4px;
    }
    svg {
      margin-left: 8px;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
    }
    .text-field__wrapper {
      margin: 0 0 0 8px;
    }
  }
  &__text {
    margin: 8px 0;
    color: $text--dark;
    strong {
      color: $text--dark;
    }
    a {
      color: $blue;
      text-decoration: underline;
    }
    small {
      display: block;
      color: $text--dark;
    }
    .warning {
      color: $orange;
    }
    .error {
      color: $red;
    }
  }
  &__subheader {
    color: $dark-85;
    margin: 8px 0;
    font-size: 1em;
    p {
      color: $dark-75;
      font-size: 0.9em;
      display: inline;
    }
  }
  &__preview {
    height: 250px;
    border: thin solid $border;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  &__btn {
  }
  &__error {
    color: $red;
    margin-right: 4px;
  }
  &__warning {
    color: $red;
    margin-right: 4px;
  }
  &__warning {
    color: $orange;
    margin-right: 4px;
  }
  &__highlight {
    color: $mint;
    margin-right: 4px;
  }
  &__picker {
    height: 500px;
  }
  &__link {
    background: $primary;
    outline: none;
    font-size: 1em;
    color: $white;
    border: thin solid $primary;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;
    padding: 8px 12px;
    box-shadow: -2px 4px 4px 0px #ccc;
    transition: all 0.2s;
    &:hover {
      background: $primary--light;
    }
    &:active {
      box-shadow: none;
    }
  }
  &__action {
    background: transparent;
    outline: none;
    font-size: 1em;
    color: $orange;
    border: none;
    cursor: pointer;
    margin: 8px 0;
    display: flex;
    padding: 4px;
    text-decoration: underline;
    &:hover {
      color: $orange--light;
    }
  }
}
