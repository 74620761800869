@import 'color/colors';
@import 'navigation/navigation';

.footer__main {
  background-color: $white;
  flex-shrink: 1;
  width: 100%;
  clear: both;
  border-top: 1px solid $light-95;
  z-index: 6;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0 0 0 5px;
  list-style: none;
  margin-right: 95px;
  @media (max-width: 876px) {
    margin-right: 20px;
  }
}

.footer__logo {
  height: 30px;
  color: $dark;
  padding: 4px 0;
  filter: brightness(0);
}

.footer__list-item {
  margin: 0 4px;
  display: flex;
  font-size: 10px;
  color: $dark;
  align-items: center;
  .myBtn--link {
    font-size: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__link {
  padding: 5px;
}

.footer__link:hover {
  text-decoration-line: underline;
}
