@import 'color/colors';

.billing {
  &__empty {
    margin-top: 20px;
    h3 {
      @include text--h2;
    }
  }
  &__modal {
    h1 {
      @include plans__h1;
    }
    h2 {
      @include plans__h2;
      margin-bottom: 4px;
    }
    h3 {
      @include plans__h3;
      margin-bottom: 8px;
    }
    h4 {
      @include plans__p2;
    }
    p {
      @include plans__p1;
      margin-bottom: 4px;
    }
    form {
      margin-top: 12px;
    }
    position: relative;
    max-width: 90%;
    min-height: 260px;
    height: fit-content;
    width: 600px;
    margin: 80px auto 40px;
    padding: 20px 40px 20px;
    outline: none;
    border: none;
    border-radius: 16px;
    box-shadow: $shadow__table;
    display: flex;
    flex-direction: column;
    background-color: $white;
  }
  &__modalOverlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
  }
  &__modalActions {
    flex: 3;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: space-between;
    h1 {
      flex: 2;
      text-align: right;
      margin-right: 20px;
    }
  }
  h1 {
    @include plans__h1;
  }
  h2 {
    @include plans__h2;
    margin-bottom: 4px;
  }
  h3 {
    @include plans__h3;
    margin-bottom: 8px;
  }
  h4 {
    @include plans__p2;
  }
  p {
    @include plans__p1;
    margin-bottom: 4px;
  }
  &__stripeContainer.StripeElement {
    border-radius: 12px;
    height: 50px;
    border: thin solid $text--light;
    padding-top: 12px;
  }
  &__inputRow {
    display: flex;
    justify-content: space-between;
    & > div:first-child {
      margin-right: 16px;
      flex: 1.1;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
  }
  .myBtn--link {
    padding-left: 0;
    text-decoration: none;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__stripeError {
    padding: 8px 20px;
    border-radius: 8px;
    background-color: $red--light;
    h4 {
      color: white;
    }
  }
  &__plaidAction {
    max-width: 280px;
    padding-right: 8px;
    svg {
      height: 20px;
      width: 80px;
      margin-left: -8px;
    }
    &:hover {
      svg {
        filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
          brightness(95%) contrast(85%);
      }
    }
  }
}

.billing__methods {
  display: grid;
  width: 100%;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-start;
}

.billing__card {
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;
  margin-left: 30px;
  h1 {
    @include plans__h1;
  }
  h2 {
    @include plans__h2;
    margin-bottom: 4px;
    font-size: 22px;
  }
  h3 {
    @include plans__h3;
    margin-bottom: 8px;
  }
  h4 {
    @include plans__p2;
  }
  p {
    @include plans__p1;
    margin-bottom: 4px;
  }
  .styled-checkbox__label {
    font-size: 12px;
  }
}

.billing__cardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  h4 {
    @include text--h4;
  }
  &--selected:hover {
    h4 {
      color: $link--light;
    }
  }
}

.billing__cardMethod {
  &#{&} {
    color: $low;
    &--med {
      color: $med;
    }
    &--warning {
      color: $warning;
    }
  }
}

.billing__cardAction {
  margin-top: 0;
  svg {
    margin-left: 4px;
    font-size: 12px;
    margin-top: 2px;
  }
}

.billing__cardDetail {
  margin-top: 24px;
}

.billing__actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
}

.billing__actionCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.billing__cardExp {
  color: $dark-50;
  font-size: 12px;
  font-weight: lighter;
  margin-top: 4px;
}

.billing__cardBrand {
  font-size: 26px;
  max-width: fit-content;
  color: $white;
  background-color: $dark-75;
  padding: 0 1px;
  border-radius: 4px;
  max-height: 24px;
}
