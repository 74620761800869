@import 'color/colors';

.register {
  width: 524px;
  margin: 40px auto 0;
  background-color: $white;
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow__table;
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 95vw;
  }
}

.register__logo {
  margin-bottom: 8px;
  text-align: center;
  text-align: center;
}
.register__savvi {
  width: 220px;
  transition: 0.05s all linear;
  cursor: pointer;
  filter: brightness(0);
}
.register__paperos {
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: flex-end;
  margin-right: -4px;
  margin-top: -36px;
  h4 {
    font-size: 10px;
    color: #40371c;
    // margin: 4px 0 0 18px;
    margin: 4px 6px 0 0;
  }
}

.register__form {
  max-width: 540px;
  margin: 0 32px 10px;

  .grid--flex {
    justify-content: space-between;
  }

  .styled-checkbox__row label {
    @include text--body1;
  }
  @media (max-width: 768px) {
    margin: 10px auto;
  }
  a:hover {
    text-decoration: underline;
    color: $link--light;
  }
}

.register__actions {
  display: flex;
  justify-content: space-between;
  .oldbtn {
    margin: 10px 0;
  }
}

.register__header {
  text-align: center;
  font-weight: 400;
  color: #434c5f;
  margin-bottom: 40px;
}
