@import 'navigation/navigation';
@import 'color/colors';

.dashboard__container {
  width: 100%;
  display: flex;
  flex: 1;
}

.dashboard__main {
  margin-top: $topbar-height;
  display: flex;
  flex-direction: column;
  background-color: $workbench--background;
  flex: 1;
  overflow-x: hidden;
  margin-bottom: 20px;
}

// Children View Styles

.dashboard__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  padding: 0 0 0 100px;
  .documentsSection {
    max-width: 1080px;
  }
  .pageLoader__wrapper {
    margin-top: 40px;
  }
  @media (max-width: 1440px) {
    padding: 0 20px 0 80px;
  }
  @media (max-width: 1280px) {
    padding: 0 20px 0 60px;
  }
  @media (max-width: 876px) {
    padding: 0 20px 0 20px;
  }
  @media (max-width: 768px) {
    padding: 0 0 0 16px;
  }
  @media (min-width: 1400px) {
    max-width: 1280px;
    overflow-x: visible;
    // .documentsSection {
    //   max-width: 1080px;
    // }
  }
  @media (min-width: 2300px) {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  &--partners {
    max-width: unset;
  }
}

.dashboard__empty {
  .myBtn--link {
    font-size: inherit;
  }
}

.dashboard__inner {
  padding: 0 10px 20px 0;
  max-width: 980px;
  width: 100%;
  @media (max-width: 1280px) {
    width: fit-content;
    max-width: 900px;
  }
  @media (max-width: 1080px) {
    max-width: 840px;
  }
  @media (max-width: 876px) {
    max-width: 800px;
  }
  @media (min-width: 1400px) {
    max-width: 980px;
    .documentsSection {
      max-width: 1060px;
    }
  }
  @media (min-width: 2300px) {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.dashboard__search {
  align-items: center;
  color: $dark-75;
  display: flex;
  height: $topbar-height - 2px;
  position: fixed;
  top: 0;
  width: 440px;
  max-width: 80vw;

  z-index: 5;
  transition: 0.5s;
  &:focus-within {
    z-index: 6;
    width: 600px;
  }
  input {
    outline: none;
    height: 40px;
    border-radius: 16px;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: thin solid #e8eef1;
  }
  &Icon {
    position: absolute;
    margin-left: 12px;
    text-align: center;
    svg {
      color: $text--light;
    }
  }
  &Clear {
    margin: 28px 8px;
    font-size: 1.1em;
    color: $text--light;
    cursor: pointer;
    position: absolute;
    right: 8px;
    &:hover {
      color: $primary;
      font-weight: bolder;
    }
  }

  @media (max-width: 1679px) {
    width: 360px;
    &:focus-within {
      width: 600px;
    }
  }
  @media (max-width: 1480px) {
    width: 300px;
    &:focus-within {
      width: 520px;
    }
  }
  @media (max-width: 1380px) {
    width: 200px;
    input {
      padding-right: 20px;
    }
    &:focus-within {
      width: 480px;
    }
  }
  @media (max-width: 1080px) {
    width: 120px;
    input {
      padding-right: 12px;
    }
    &:focus-within {
      width: 380px;
    }
  }
  @media (max-width: 560px) {
    width: 80px;
  }
}
