@import 'color/colors';
@import 'navigation/navigation';

.searchBar {
  align-items: center;
  color: $dark-75;
  display: flex;
  position: relative;
  height: $topbar-height - 2px;
  max-width: 80vw;
  width: 320px;

  z-index: 5;
  transition: 0.5s;
  cursor: pointer;
  input {
    outline: none;
    height: 40px;
    border-radius: 16px;
    padding-right: 40px;
    padding-left: 40px;
    color: $text--dark;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: $hover;
    border: thin solid #e8eef1;
  }
  &--empty {
    width: 60px;
    input {
      padding-right: 20px;
      background-color: $white;
      border: none;
    }
  }
  &:hover {
    .searchBar__icon {
      color: $link;
    }
  }
  &:focus-within {
    z-index: 6;
    width: 460px;
    &:hover {
      .searchBar__icon {
        color: $text--dark;
      }
    }
    input {
      border: thin solid #e8eef1;
    }
  }
  &__icon {
    position: absolute;
    margin-left: 12px;
    text-align: center;
    color: $text--dark;
  }
  &__clear {
    margin: 28px 8px;
    font-size: 1.1em;
    color: $text--dark-1;
    cursor: pointer;
    position: absolute;
    right: 8px;
    &:hover {
      color: $primary;
      font-weight: bolder;
    }
  }

  @media (max-width: 1679px) {
    // width: 320px;
    &:focus-within {
      width: 420px;
    }
  }
  @media (max-width: 1480px) {
    // width: 300px;
    &:focus-within {
      width: 400px;
    }
  }
  @media (max-width: 1380px) {
    // width: 200px;
    input {
      padding-right: 20px;
    }
    &:focus-within {
      width: 380px;
    }
  }
  @media (max-width: 1080px) {
    // width: 120px;
    input {
      padding-right: 12px;
    }
    &:focus-within {
      width: 380px;
    }
  }
  // @media (max-width: 560px) {
  //   width: 80px;
  // }
}
