@import 'navigation/navigation';
@import 'color/colors';

.workbench-nav__link {
  width: 100%;
  padding: 12px 8px 12px 36px;
  border: thin solid transparent;
  border-bottom: thin solid $border--dark;
  border-left: 6px solid transparent;
  display: flex;
  // align-items: center;
  transition: 0.2s;

  svg {
    font-size: 14px;
    font-weight: 500;
    color: $text--light;
    margin: 6px 12px 0 0;
    transition: all 0.2s ease-in-out;
  }

  // &-padding {
  //   width: 19px;
  // }

  &:hover {
    outline: none;
    border-left-color: $new-primary;
    h4 {
      color: $new-primary;
    }
    svg {
      color: $new-primary;
      transform: rotate(90deg);
    }
    .workbench-nav__link-items {
      h4 {
        color: $new-primary;
      }
    }
  }
  &:focus {
    outline: none;
  }

  &-items {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 4px 0;
      color: $text--light;
      overflow-wrap: anywhere;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin: 8px 0 8px 16px;
      overflow-wrap: anywhere;
      color: $text--light;
    }
  }

  &--selected {
    border-left-color: $new-primary;
    h4 {
      font-weight: 800;
      color: $new-primary;
    }
    svg {
      transform: rotate(90deg);
    }
    &:hover {
      h4 {
        color: $new-primary--light;
      }
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.workbench-nav__exit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  height: $topbar-height - 2px;
  // font-size: 12px;
  // color: $text--dark;
  // font-weight: normal;

  color: #0f1924;
  font-weight: 700;
  font-size: 16px;
  // background-color: $white;
  position: fixed;
  top: 0;
  left: 300px;
  // width: $sidebar-width - 80px;
  width: unset;
  margin-left: -30px;
  outline: none;
  border: none;
  cursor: pointer;
  backdrop-filter: blur(1px);

  svg {
    font-size: 16px;
    margin: 0 4px;
  }

  &:hover {
    color: $primary--light;
    text-decoration: underline;
  }
  &.warning {
    color: $orange;
    left: 240px;
    width: unset;
    &:hover {
      color: $orange--light;
    }
  }
  @media (max-width: 1080px) {
    width: unset;
  }
}

.workbench-nav__fade {
  top: 100px;
  bottom: 0;
  left: 0;
  width: 59px;
  overflow-y: hidden;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, $bg--nav 100%);
  position: fixed;
  z-index: 1;
}

.workbench-nav__content {
  display: flex;
  flex-direction: column;
  padding: 60px 0 40px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  border-top: thin solid $border--light;
  top: $topbar-height - 1px;
  bottom: 0;
  width: $sidebar-width;
  transition: all 0.2s ease-in-out;
}

.workbench-nav__inner {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 12px;
  width: $sidebar-width;
  flex: 1;
}

.workbench-nav__group {
  padding: 0;
  margin: 10px 0;
  max-height: 750px;
  transition: all 0.25s;
  display: flex;
  flex-direction: column;
  width: 100%;
  &-header {
    margin: 0;
    padding: 10px 8px 0 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    h2 {
      @include text--h2;
      color: $text--dark-1;
      margin: 0;
      word-break: break-word;
    }
  }
  &.collapsed {
    max-height: 41px;
    overflow: hidden;
  }
  &.borderless {
    box-shadow: none;
    max-height: 3000px;
  }
}
