@import 'color/colors';

.verify-link-actions__secondary {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  margin-bottom: 12px;
}

.verify-link__container {
  width: 480px;
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 435px;
  .savvi-loading {
    margin: auto;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }
}

.verify-link__logo {
  margin-bottom: 20px;
  img {
    width: 180px;
    transition: 0.05s all linear;
    cursor: pointer;
    filter: brightness(0);
  }
}
.verify-link__paperos {
  font-size: 10px;
  color: #40371c;
  margin: -30px 0 0 0;
  text-align: end;
}

.verify-link__form {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
  background-color: $white;
  padding: 18px 26px;
  margin: 0 auto;
}

.verify-link__heading {
  margin-top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: thin solid $border;
  border-left: none;
  border-right: none;
  padding: 18px 26px;
  border-radius: 4px;
  h2 {
    @include text--h1;
    margin: 4px 0;
  }
  h3 {
    @include text--h2;
    svg {
      color: $warning;
      margin-right: 4px;
    }
  }
  .myBtn--primary {
    margin: 12px 0;
  }
  .myBtn--link {
    font-size: inherit;
    margin-top: 4px;
  }
  &--error {
    color: $warning;
  }
}

.verify-link__sub-heading {
  display: block;
  margin-top: 12px;
  margin-bottom: 4px;
  height: 40px;
  color: $dark-85;
}

.verify-link__action {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .myBtn {
    width: 348px;
    margin-bottom: 2px;
    &--secondary {
      margin-top: 20px;
    }
    img {
      padding-right: 2px;
      margin-right: 6px;
      width: 20px;
    }
  }
}

.verify-link__link {
  color: $link;
  position: relative;
  margin-left: 4px;
  font-size: 1em;
  cursor: pointer;
  svg {
    font-size: 0.9em;
  }
}

.verify-link__text {
  text-align: center;
  p {
    margin-bottom: 12px;
  }
}
