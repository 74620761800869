@import 'color/colors';

.savviLoading {
  /** Savvi Loading SVG Styling **/
  &__svg {
    text-align: center;
    align-self: center;

    width: 185px;
    height: 180px;
  }
  &__gOuter {
    stroke: none;
    stroke-width: 1;
    fill: none;
    fill-rule: evenodd;
    transform: translate(92.5px, 92.4997px) translate(-87.5px, -106.238px);
  }
  &__gInner {
    fill: #d8d8d8;
    fill-rule: nonzero;
    stroke: #0c6987;
    stroke-width: 15;
    transform: translate(87.5px, 74.7383px) rotate(-45px) translate(-58px, -58px);
    animation: 2s linear infinite both Savvi-Rectangle-Folded_t;
  }
  &__pathFirst {
    fill-opacity: 0;
    animation: 2s linear infinite both Rectangle_d;
  }
  &__pathSecond {
    stroke-linecap: square;
    stroke-linejoin: bevel;
    opacity: 1;
    animation: 2s linear infinite both Line_o;
  }
  /** End of Savvi Loading SVG Styling **/
  &--sm {
    width: 25px;
  }
  &--md {
    width: 75px;
  }
  &--lg {
    width: 185px;
    @media (max-width: 876px) {
      width: 160px;
    }
  }
  &--black {
    filter: brightness(0);
  }
  &--white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7461%)
      hue-rotate(255deg) brightness(106%) contrast(89%);
  }
  &--mint {
    filter: brightness(0) saturate(100%) invert(87%) sepia(47%) saturate(774%)
      hue-rotate(100deg) brightness(91%) contrast(80%);
  }
  &--powderblue {
    filter: brightness(0) saturate(100%) invert(86%) sepia(17%) saturate(295%)
      hue-rotate(134deg) brightness(98%) contrast(94%);
  }
  &--cerulean {
    filter: brightness(0) saturate(100%) invert(32%) sepia(51%) saturate(878%)
      hue-rotate(150deg) brightness(94%) contrast(93%);
  }
  &--cerulean-light {
    filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
      brightness(95%) contrast(85%);
  }
  &--manilla {
    filter: brightness(0) invert(79%) sepia(32%) saturate(601%) hue-rotate(359deg)
      brightness(105%) contrast(102%);
  }
}

/*** savvi_loading.svg styling ***/
@keyframes Savvi-Rectangle-Folded_t {
  0% {
    transform: translate(87.5px, 74.7383px) rotate(-45deg) translate(-58px, -58px);
  }
  7.5% {
    transform: translate(87.5px, 74.7383px) rotate(-45deg) translate(-58px, -58px);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  26% {
    transform: translate(87.5px, 91.2383px) rotate(0deg) translate(-58px, -58px);
  }
  65% {
    transform: translate(87.5px, 91.2383px) rotate(0deg) translate(-58px, -58px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  96.5% {
    transform: translate(87.5px, 74.74px) rotate(-45deg) translate(-58px, -58px);
  }
  100% {
    transform: translate(87.5px, 74.74px) rotate(-45deg) translate(-58px, -58px);
  }
}
@keyframes Rectangle_d {
  0% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.5,115.5L115.5,115.5L115.5,107.76L115.5,58L58,58L58,0.5Z'
    );
  }
  15% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.5,115.5L115.5,115.5L115.5,107.76L115.5,58L58,58L58,0.5Z'
    );
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  26% {
    d: path('M0.5,0.5L0.5,108.307L0.5,124L115.5,124L115.5,107.76L115.5,58L58,58L58,0.5Z');
  }
  40% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.5,124L115.5,124L115.5,107.76L115.5,58L115.5,0.5L58,0.5Z'
    );
  }
  46% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.5,124L115.5,124L115.5,107.76L115.5,58L115.5,0.5L58,0.5Z'
    );
  }
  52% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.5,124L115.5,124L115.5,107.76L115.5,58L115.5,0.5L58,0.5Z'
    );
  }
  66.5% {
    d: path('M0.5,0.5L0.5,108.307L0,124L115.5,124L115.5,107.76L115.5,58L58,58L58,0.5Z');
  }
  74% {
    d: path('M0.5,0.5L0.5,108.307L0,124L115.5,124L115.5,107.76L115.5,58L58,58L58,0.5Z');
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  89% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.481765,115.375L115.5,115.375L115.5,107.76L115.5,58L58,58L58,0.5Z'
    );
  }
  100% {
    d: path(
      'M0.5,0.5L0.5,108.307L0.481765,115.375L115.5,115.375L115.5,107.76L115.5,58L58,58L58,0.5Z'
    );
  }
}
@keyframes Line_o {
  0% {
    opacity: 1;
  }
  32% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  37% {
    opacity: 0;
  }
  58% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  63% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
