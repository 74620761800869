@import 'navigation/navigation';
@import 'color/colors';

.workbench__container {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: $workbench--background;
  &.info-sidebar-collapsed {
    .workbench__main {
      margin-right: 0;
    }
    .infoSidebar__container {
      width: 0;
      overflow: hidden;
      padding: 0;
    }
    .workbench__toggle-info {
      right: 12px;
      top: 64px;
      height: 40px;
      width: 40px;
      min-width: 40px;
      box-shadow: rgba(0, 0, 0, 10%) 0px 1px 6px, rgba(0, 0, 0, 20%) 0px 2px 24px;
      color: $link--light;
      background-color: $white;
      border-color: $border;
      transition: 0.05s ease;
      svg {
        color: $link--light;
      }
      &:active {
        box-shadow: none;
        border-color: $link--light;
      }
      &:hover {
        border-color: $link--light;
      }
      @media (min-width: 1440px) {
        right: unset;
      }
    }
    .workbench__info-sidebar-overlay {
      display: none;
    }
  }
}

.workbench__loader {
  margin: 1em;
  font-size: 42px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.workbench__main {
  margin-top: $topbar-height;
  margin-right: $info-sidebar-width;
  display: flex;
  background-color: $workbench--background;
  flex: 1;
  width: 100%;
  // overflow-x: hidden;
  // overflow-y: hidden;
  @media (min-width: 1540px) {
    margin-right: 0;
    flex: unset;
  }
  @media (min-width: 1700px) {
    &--portal {
      justify-content: center;
    }
  }
  @media (max-width: 1280px) {
    margin-right: $info-sidebar-width--sm;
  }
  @media (max-width: 876px) {
    margin-right: 0;
  }
  &--portal {
    margin-right: unset;
    overflow: auto;
  }
}

.workbench__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 40px 80px 160px;
  max-width: 960px;
  & > h2 {
    min-width: 680px;
  }
  @media (min-width: 1780px) {
    padding: 60px 100px 160px;
    max-width: 1000px;
    // .workbench__banner h4 {
    //   width: 1000px;
    // }
  }
  @media (max-width: 1440px) {
    padding: 40px 40px 100px;
  }

  @media (max-width: 1080px) {
    padding: 40px 20px 100px;
  }
  @media (max-width: 876px) {
    padding: 40px 4px 100px;
    &--form {
      overflow-x: hidden;
    }
  }
}

.workbench__banner {
  height: 30px;
  z-index: 4;
  background-color: $orange--light-1;
  position: fixed;
  top: 68px;
  margin: 2px auto;
  width: fit-content;
  border-radius: 8px;
  max-width: 100vw;
  @media (max-width: 1280px) {
    padding-left: 70px;
    overflow-x: auto;
  }
  &.second {
    top: 36px;
    z-index: 10;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    width: 960px;
    max-width: 100%;
    color: $white;
    text-align: center;
    margin: 0;
    line-height: 30px;
    white-space: nowrap;
    svg {
      color: $red;
      margin-right: 4px;
    }
    a {
      color: $link__light-bg;
      &:hover {
        color: $link__light-bg--lighten;
        text-decoration: underline;
      }
    }
    @media (max-width: 1280px) {
      @include text--h3;
      color: $white;
    }
    @media (max-width: 876px) {
      @include text--h4;
      color: $white;
    }
  }
}

.workbench__formLoader {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 42px;
  color: $primary;
  img {
    width: 2em;
  }
}

.workbench__loading {
  display: block;
  align-self: center;
  padding: 0.25em;
  color: $text--dark;
}

.workbench__label {
  @include text--h3;
  color: $text--dark-1;
  display: flex;
  font-size: 14px;
  color: $text--dark;
}

.workbench__toggle-info {
  position: fixed;
  top: 62px;
  right: 220px;
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  svg {
    color: $text--dark-1;
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;
    svg {
      color: $link;
    }
  }

  @media (min-width: 1280px) {
    right: 280px;
  }
  @media (min-width: 1440px) {
    right: unset;
    left: unset;
    margin-left: 1000px;
  }
  @media (max-width: 876px) {
    top: 4px;
    right: 180px;
  }
}
.workbench__toggle-info-action {
  position: absolute;
  opacity: 1;
  transition: 0.25s ease-in-out;
}
.workbench__info-sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($dark-85, 0.5);
  z-index: $sidebar-z-index + 3;
  @media (max-width: 876px) {
    display: block;
  }
}
