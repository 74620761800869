@import 'color/colors';

.input-textarea {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    max-width: 600px;
    width: 100%;
    &--error {
      .styled-text-field__label {
        color: $red;
      }
      .styled-text-field {
        border-color: $red;
      }
    }
  }
  &__label {
    @include text--label;
    text-align: start;
    position: relative;
  }
  @include text--input;
  border-color: $text--light;
  border-radius: 10px;
  padding: 16px;
  height: 100%;
  outline: none;
  position: relative;
  width: 100%;
  &:disabled {
    background-color: #eee;
  }
  &:focus {
    border-color: $border--active;
  }
}
