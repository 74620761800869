@import 'color/colors';

.roomInvite__container {
  width: 480px;
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 435px;
  .savvi-loading {
    margin: auto;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }
}

.roomInvite__logo {
  margin-bottom: 20px;
  img {
    width: 180px;
    transition: 0.05s all linear;
    cursor: pointer;
    filter: brightness(0);
  }
}

.roomInvite__heading {
  margin-top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: thin solid $border;
  border-left: none;
  border-right: none;
  padding: 18px 26px;
  border-radius: 4px;
  h2 {
    @include text--h1;
    margin: 0 0 8px;
  }
  h3 {
    @include text--h2;
    svg {
      color: $warning;
      margin-right: 4px;
    }
  }
  .myBtn--link {
    font-size: inherit;
  }
  &--error {
    color: $warning;
  }
}

.roomInvite__text {
  text-align: center;
  p {
    margin-bottom: 12px;
  }
}
